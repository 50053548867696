import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Stack, TableCell, TextField, Typography } from '@mui/material';
import { Context } from '../App';
import { API } from '../asset/conf';
import { Add, Close, Delete, Edit, History, InfoOutlined, Search } from '@mui/icons-material';
import ClientForm from './client-form';
import SorTable from './sortable'
import moment from 'moment';
import xlsx from 'json-as-xlsx';

export default function Client() {
  const { updateConnect } = useContext(Context)
  const [load, setLoad] = useState(true)
  const [clients, setClients]= useState([])
  const [client, setClient]= useState()
  const [history, setHistory] = useState([])
  const [open, setOpen] = useState(false)
  const [action, setAction] = useState('')
  const [search, setSearch] = useState('')

  const table = [
    {header: "Nom", sortable: true, sortBy:el=>el.nom.toLowerCase()+' '+el.prenom.toLowerCase(), 
    row: (row, index)=><TableCell key={index} sx={{minWidth:'150px', textTransform:'capitalize'}} align="center">{(row.sex==='F'?'mme ':'mr ')+(row.nom+' '+row.prenom).toLowerCase()}</TableCell>},
    {header: "Adresse mail", sortable: true, sortBy:el=>el.mail, 
    row: (row, index)=><TableCell key={index} sx={{minWidth:'150px', width:'100%'}} align="center">{row.mail.toLowerCase()}</TableCell>},
    {header: "Téléphone", sortable: true, sortBy:el=>el.tel, 
    row: (row, index)=><TableCell key={index} sx={{minWidth:'120px'}} align="center">{row.tel}</TableCell>},
    {header: "Date de naissance", sortable: true, sortBy:(el)=>new Date(el.birth_date).getTime(), 
    row: (row, index)=><TableCell key={index} align="center" sx={{minWidth:'90px'}} >{moment.utc(row.birth_date).format('DD/MM/YYYY')}</TableCell>},
    {header: "Pièce d'identité", sortable: true, sortBy:el=>el.identity_proof?.type+' '+el.identity_proof?.num, 
    row: (row, index)=><TableCell key={index} sx={{minWidth:'150px'}} align="center">{row.identity_proof?.type+' n°'+row.identity_proof?.num}</TableCell>},
    {header:'', row:(row, index)=> <TableCell key={index} align='center' sx={{maxWidth:'200px', minWidth:'150px'}}>
      <IconButton aria-label="informations complémentaires" onClick={()=>{setClient(row); manage_dialog(true, 'info')}}><InfoOutlined fontSize="small"/></IconButton>
      <IconButton aria-label="modifier" onClick={()=>{setClient(row); manage_dialog(true, 'edit')}}><Edit fontSize="small"/></IconButton>
      <IconButton aria-label="supprimer" onClick={()=>{setClient(row); manage_dialog(true, 'delete')}}><Delete fontSize="small"/></IconButton>
      <IconButton aria-label="historique" onClick={()=>{setClient(row); get_history(row)}}><History fontSize="small"/></IconButton>
    </TableCell>}
  ]

  const history_table = [
    {header:'N° Billet', sortable: true, sortBy:(el)=>el.num, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.num}</TableCell>},
    {header:'Date départ', sortable: true, sortBy:(el)=>new Date(el.rotation.date_depart).getTime(), row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'90px'}}>{moment.utc(row.rotation.date_depart).format('DD/MM/yyyy')}</TableCell>},
    {header:'Départ', sortable: true, sortBy:(el)=>el.rotation.from.nom.toLowerCase(), row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.rotation.from.nom}</TableCell>},
    {header:'Arrivé', sortable: true, sortBy:(el)=>el.rotation.to.nom.toLowerCase(), row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.rotation.to.nom}</TableCell>},
  ]

  async function manage_dialog(state, action) {
    setOpen(state)
    setAction(action)
    if (!state) {
      setClient()
    }
  }

  function normalize(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9\s-.€]/gi,'').toLowerCase()
}

function get_searchable(txt) {
  return txt.nom+' '+txt.prenom+' '+moment.utc(txt.birth_date).format('DD/MM/YYYY')+' '+txt.mail+' '+txt.tel+' '+txt.identity_proof.num
}

function get_history(psg) {
  setLoad(true)
  fetch(API.concat(`/passagers/${psg._id}/history`), {method:'GET', headers:{
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + sessionStorage.getItem('token')
  }}).then(async response=>{
    const token = response.headers.get('x-auth-token')
    if (response.status === 401) {
      sessionStorage.clear()
      updateConnect(false)
    } else {
      if (token && token !== sessionStorage.getItem('token') && token !== '') {
        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
      }
      if (response.status === 200) {
        const {blts, rtn, ports} = await response.json()
        Promise.all(rtn.map(item=>{return new Promise((resolve, reject) => {
          item.from = ports.find(p=> p._id===item?.from)
          item.to = ports.find(p=> p._id===item?.to)
          resolve(item)
        })})).then(rotations=>{
          Promise.all(blts.map(item=>{return new Promise((resolve, reject) => {
              item.passager = psg
              item.rotation = rotations.find(r=>r._id===item.rotation)
              resolve(item)
          })})).then(results=>{
              setHistory(results);
              manage_dialog(true, 'history')
              setLoad(false)
          })
        })
      }else setLoad(false)
    }
  })
}

function handleExport() {
  let excel = [
      {
        sheet: "Clients Cappo Rosso",
        columns: [
          { label: "Sexe", value: (row) => row.sex }, 
          { label: "Nom", value: (row) => row.nom.toUpperCase() }, 
          { label: "Prénom", value: (row) => row.prenom.charAt(0).toUpperCase() + row.prenom.slice(1) }, 
          { label: "Mail", value: (row)=>row.mail }, 
          { label: "Tél", value: (row) => row.tel }, 
          { label: "Date de naissance", value: (row) => moment.utc(row.birth_date).format('DD-MM-yyyy') }, 
          { label: "Lieu de naissance", value: (row) => row.birth_place }, 
          { label: "Nationalité", value: (row) => row.nationality }, 
          { label: "Pièce d'identité", value: (row) => row.identity_proof?.type }, 
          { label: "N° pièce d'identité", value: (row) => row.identity_proof?.num }, 
          { label: "Délivrée", value: (row) => row.identity_proof?.ddate?moment.utc(row.identity_proof?.ddate).format('DD-MM-yyyy'):'' }, 
          { label: "Expire", value: (row) => row.identity_proof?.exp_date?moment.utc(row.identity_proof?.exp_date).format('DD-MM-yyyy'):'' }, 
          { label: "Emetteur", value: (row) => row.identity_proof?.emetteur }, 
          { label: "N° titre de séjour", value: (row) => row.tds_proof?.num }, 
          { label: "TDS Délivrée", value: (row) => row.tds_proof?.ddate?moment.utc(row.tds_proof?.ddate).format('DD-MM-yyyy'):'' }, 
          { label: "TDS Expire", value: (row) => row.tds_proof?.exp_date?moment.utc(row.tds_proof?.exp_date).format('DD-MM-yyyy'):'' }, 
          { label: "PMR", value: (row) => row.isPmr?'oui':'non' }, 
          { label: "Web", value: (row) => row.isWeb?'oui':'non' }, 
          { label: "Observations", value: (row) => row.observation }, 
        ],
        content: clients.sort((a,b)=>(a.nom+' '+a.prenom).toLowerCase()<(b.nom+' '+b.prenom).toLowerCase()?-1:1),
      },
  ]
    
  let settings = {
      fileName: `liste_clients_${Date.now().toLocaleString()}`, // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
  }
    xlsx(excel, settings) // Will download the excel file
}

function handleDelete() {
  setLoad(true)
  fetch(API.concat(`/passagers/${JSON.stringify({_id: client._id})}`), {method:'DELETE', headers:{
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
}).then(async(response)=>{
    const token = response.headers.get('x-auth-token')
    if (response.status === 401) {
        sessionStorage.clear()
        updateConnect(false)
    } else {
        if (token && token !== sessionStorage.getItem('token') && token !== '') {
            sessionStorage.setItem('token', response.headers.get('x-auth-token'))
        }
        if (response.status === 200) {
            setClients(old => old.filter(cl=> cl._id !== client._id))
            setLoad(false)
            manage_dialog(false,'')
        }
    }
})
}

  useEffect(()=>{
    function get_clients() {
      fetch(API.concat('/passagers'), {method:'GET', headers:{
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      }}).then(async response=>{
        const token = response.headers.get('x-auth-token')
        if (response.status === 401) {
          sessionStorage.clear()
          updateConnect(false)
        } else {
          if (token && token !== sessionStorage.getItem('token') && token !== '') {
            sessionStorage.setItem('token', response.headers.get('x-auth-token'))
          }
          if (response.status === 200) {
            const json = await response.json()
            const webClients = Array.from(new Map(json.filter(cl => cl.isWeb).map(cl => [`${cl.nom.toLowerCase()}-${cl.prenom.toLowerCase()}-${cl.mail}`,{nom: cl.nom.toLowerCase(), prenom: cl.prenom.toLowerCase(), mail: cl.mail}])).values());
            setClients([...json.filter(cl=>!cl.isWeb),
              ...webClients.map(wcl=>json.find(cl=>cl.isWeb&&cl.nom.toLowerCase()===wcl.nom&&cl.prenom.toLowerCase()===wcl.prenom&&cl.mail===wcl.mail&&
              !json.some(c=>c.isWeb&&c.nom.toLowerCase()===wcl.nom&&c.prenom.toLowerCase()===wcl.prenom&&c.mail===wcl.mail&&(moment.utc(c.identity_proof.exp_date).isAfter(moment.utc(cl.identity_proof.exp_date))||(c.tds_proof?.exp_date&&cl.tds_proof?.exp_date?moment.utc(c.tds_proof.exp_date).isAfter(moment.utc(cl.tds_proof.exp_date)):false)))))
            ])
          }
          setLoad(false)
        }
      })
    }

    get_clients();
  }, [updateConnect])

    return (
      <Box style={{display:'flex', flex:1, marginBottom:30, paddingInline:'30px', flexDirection:'column'}}>
        <Typography variant='h1' align='center'>Gestion des clients</Typography>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:'15px'}}>
          <TextField
          size="small" label="Rechercher un client..." disabled={!clients?.length>0}
          sx={{width:'60%', minWidth:'300px', marginTop:'10px'}} value={search} onChange={(event)=> setSearch(event.target.value)}
          InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
          }}
          /> 
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Button variant='contained' startIcon={<Add/>} onClick={()=> manage_dialog(true, 'add')}>Ajouter</Button>
            <Button variant='contained' onClick={handleExport}>Exporter</Button>
          </Stack>
        </Box>
        <SorTable data={clients.filter(item=> normalize(get_searchable(item)).includes(normalize(search)))} table={table} rowsPerPage={100} rowsPerPageOptions={[25, 50, 100, 300]}/>

        <Dialog fullWidth maxWidth={action==='delete' ? 'xs' : action==='info' ? 'sm' : 'md'} open={open} sx={{minWidth:action==='delete' ?'250px':'500px', padding:'10px'}}>
          <IconButton onClick={()=>manage_dialog(false, '')} sx={{position:'absolute',right:'10px', top:'10px'}}><Close/></IconButton>
          {action==='add' && <ClientForm add={(client)=> setClients(old=> [...old, client])} close={()=>manage_dialog(false, '')} />}
          {action==='edit' && <ClientForm edit={true} client={client} add={(edited)=> setClients(old=> {
            const nclient=[...old]; nclient[nclient.indexOf(client)]=edited; return nclient
          })} close={()=>manage_dialog(false, '')} />}
          {action==='delete'&& <>
            <DialogTitle sx={{marginInline:'30px', textTransform:'capitalize'}}>Supprimer {client.nom+' '+client.prenom}</DialogTitle>
            <DialogActions sx={{marginBottom:'10px'}}>
              <Button variant='contained' color="error" onClick={handleDelete}>Comfimer</Button>
              <Button variant='contained' color='secondary' onClick={()=> manage_dialog(false,'')}>Annuler</Button>
            </DialogActions>
          </>}
          {action==='info'&& <>
            <DialogTitle align='center' sx={{marginInline:'30px', textTransform:'capitalize'}}>{(client.sex==='H'?'mr ':'mme ')+(client.nom+' '+client.prenom).toLowerCase()}</DialogTitle>
            <DialogContent sx={{marginBottom:'10px'}}>
              <Typography variant='body1'>Date de naissance: {client.birth_date}</Typography>
              <Typography variant='body1'>Lieu de naissance: {client.birth_place}</Typography>
              <Typography variant='body1'>Nationalité: {client.nationality}</Typography>
              <Typography variant='body1'>Adresse mail: {client.mail.toLowerCase()}</Typography>
              <Typography variant='body1'>Téléphone: {client.tel}</Typography>
              <Typography variant='body1'>Pièce d'identité: {client.identity_proof.type.toUpperCase()}</Typography>
              <Typography variant='body1'>N° pièce d'identité: {client.identity_proof.num}</Typography>
              <Typography variant='body1'>Date d'expiration: {client.identity_proof.exp_date}</Typography>
              <Typography>Catégorie: {client.categorie}</Typography>
              <Typography>Observation: {client.observation}</Typography>
              <Typography>PMR: {client.isPmr ? 'oui' : 'non'}</Typography>
            </DialogContent>
          </>}

          {action==='history'&&<Box display='flex' flexDirection='column' alignItems='center' margin='20px' marginTop='30px'>
            <Typography variant='h5'>Historique de voyage de <span style={{textTransform:'capitalize'}}>{client.nom+' '+client.prenom}</span></Typography>
            <SorTable data={history} table={history_table} rowsPerPage={50} rowsPerPageOptions={[25, 50, 100]}/>
          </Box>}
        </Dialog>

        <Backdrop open={load}><CircularProgress/></Backdrop>
      </Box>
    );
}