import React, { useState, useMemo, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';

function ClientSearch({ clients, setClient, value, style }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [open, setOpen] = useState(false);

  const filteredClients = useMemo(() => {
    if (searchTerm.length < 2) return [];
    
    const lowerSearchTerm = searchTerm.toLowerCase();
    return clients.filter(cl => 
      cl.nom.toLowerCase().startsWith(lowerSearchTerm) ||
      cl.prenom.toLowerCase().startsWith(lowerSearchTerm)
    );
  }, [searchTerm, clients]);

  const handleSelect = (event, client) => {
    if (client) {
      setClient(client);
      setSelectedClient(client);
    }
  };

  useEffect(() => {
    if (value && value !== '') {
      const selectedClient = clients.find(client => client._id === value);
      if (selectedClient) {
        setSelectedClient(selectedClient);
        setSearchTerm(`${selectedClient.nom} ${selectedClient.prenom}`);
      }
      setOpen(false);
    }
  }, [value, clients]);

  return (
    <Autocomplete
      sx={style}
      options={filteredClients}
      getOptionLabel={(option) => option ? `${option.nom} ${option.prenom}` : ''}
      getOptionKey={(option)=>option._id}
      renderInput={(params) => <TextField {...params} value={searchTerm} />}
      value={selectedClient}
      inputValue={searchTerm}
      onInputChange={(event, newInputValue) => {
        setSearchTerm(newInputValue);
        setOpen(newInputValue.length > 1);
      }}
      onChange={handleSelect}
      filterOptions={(x) => x}
      noOptionsText={searchTerm.length > 1 ? "Aucun client trouvé" : "Entrez au moins 2 caractères"}
      open={open}
      onOpen={() => setOpen(searchTerm.length > 1)}
      onClose={() => setOpen(false)}
    />
  );
}

export default ClientSearch;