import moment from "moment";

export function list_embarques(billets, date) {
    let content = [...new Set(billets.map(blt=>blt.rotation?.from?._id))].map(pt=>{
        var tableau1 = [
            [
                {text: 'Nom'}, 
                {text: 'Prénom', alignment: 'center'},
                {text: 'Date de naissance', alignment: 'center'},
                {text: 'Lieu de naissance', alignment: 'center'},
                {text: 'Sexe', alignment: 'center'},
                {text: "Type pièce d'identité", alignment: 'center'},
                {text: "N° pièce ", alignment: 'center'},
                {text: "Date délivrance", alignment: 'center'},
                {text: "Date d'expiration", alignment: 'center'},
                {text: "Observation", alignment: 'center'},
            ],
        ].concat(billets.filter(blt=>blt.rotation?.from?._id===pt).map(blt=>[
                {text: blt.passager.nom?.toUpperCase()}, 
                {text: blt.passager.prenom?.at(0).toUpperCase()+blt.passager.prenom?.slice(1).toLowerCase(), alignment: 'center'},
                {text: moment.utc(blt.passager.birth_date).format('DD/MM/yyyy'), alignment: 'center'},
                {text: blt.passager.birth_place, alignment: 'center'},
                {text: blt.passager.sex, alignment: 'center'},
                {text: (blt.passager.identity_proof.type+'\n'+(blt.passager.tds_proof?.num?'Titre de séjour':'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
                {text: (blt.passager.identity_proof.num+'\n'+(blt.passager.tds_proof?.num??'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
                {text: (moment.utc(blt.passager.identity_proof.ddate).format('DD/MM/yyyy')+'\n'+(blt.passager.tds_proof?.ddate?moment.utc(blt.passager.tds_proof?.ddate).format('DD/MM/yyyy'):'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
                {text: (moment.utc(blt.passager.identity_proof.exp_date).format('DD/MM/yyyy')+'\n'+(blt.passager.tds_proof?.exp_date?moment.utc(blt.passager.tds_proof?.exp_date).format('DD/MM/yyyy'):'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
                {text: blt.reservation.commentaire??''+(blt.passager?.isPmr? (blt.reservation.commentaire!==''?'\n':'')+'PMR':''), alignment: 'center'},
            ])
        )

        var tableau2 = [
            [
                {text:'', border: [false, false]},
                {text: 'Adultes', style: 'tableHeader', alignment: 'center'},
                {text: 'Enfants', style: 'tableHeader', alignment: 'center'},
                {text: 'Nourrissons', style: 'tableHeader', alignment: 'center'},
                {text: 'TOTAL', style: 'tableHeader', alignment: 'center'},
            ],
            [
                {text: 'Total', style: 'tableHeader', alignment: 'center'}, 
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie==='adulte'?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie?.includes('enfant')?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie==='nourrisson'?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).length, alignment: 'center'}
            ],
        ]

        return([{text:`Au départ de ${billets.find(blt=>blt.rotation?.from?._id===pt)?.rotation?.from?.nom}\n\n`, style:'subheader'},
            {table: {
                widths: ['auto', 'auto', 70, 'auto', 'auto',60, 'auto',70, 70,'*'],
                headerRows: 1,
                body: tableau1
            }},
            {
            table: {
                widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                headerRows: 1,
                body: tableau2
            }, margin: [0, 10, 0, 15]}
        ])
    }).flat()
    
    
    
    var dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 30, 30, 30, 30 ],
        content: [
            {text: `Liste passagers embarqués le ${moment.utc(date).format('DD/MM/yyyy')}\n\n`, alignment:'center', style: 'header'},
        ].concat(content),
        styles:{
            header_footer: {fontSize:8, bold: true},
            header: {fontSize:20, bold: true},
            light_header: {fontSize:18},
            subheader: {fontSize:16, bold: true},
            tableHeader: {bold: true},
            tableTotal: {bold: true, color: '#fff'}
        },
        defaultStyle:{fontSize: 12}        
    }
    return dd
}

export function list_equipage(rotation) {
    var tableau1 = [
        [
            {text: ''}, 
            {text: 'Num'}, 
            {text: 'Nom'}, 
            {text: 'Prénom'},
            {text: 'Nationalité', alignment: 'center'},
            {text: 'S', alignment: 'center'},
            {text: 'Date de naissance', alignment: 'center'},
            {text: 'Lieu de naissance', alignment: 'center'},
            {text: 'Document', alignment: 'center'},
            {text: 'D.Deliv', alignment: 'center'},
            {text: 'D.Exp', alignment: 'center'},
        ],
        [
            {text: 'Capitaine'}, 
            {text: rotation.captain?.num?.toUpperCase()}, 
            {text: rotation.captain?.nom?.toUpperCase()}, 
            {text: rotation.captain?.prenom?.toUpperCase()},
            {text: rotation.captain?.nationality??'', alignment: 'center'},
            {text: rotation.captain?.sex==='H'?'M':'F', alignment: 'center'},
            {text: rotation.captain?.birthDate?moment.utc(rotation.captain?.birthDate).format('DD/MM/yyyy'):'', alignment: 'center'},
            {text: rotation.captain?.birthPlace?.toUpperCase(), alignment: 'center'},
            {text: rotation.captain?.proof, alignment: 'center'},
            {text: rotation.captain?.dDate?moment.utc(rotation.captain?.dDate).format('DD/MM/yyyy'):'', alignment: 'center'},
            {text: rotation.captain?.expDate?moment.utc(rotation.captain?.expDate).format('DD/MM/yyyy'):'', alignment: 'center'},
        ]].concat(rotation.meca.map(meca=>
            [
                {text: 'Meca.'}, 
                {text: meca?.num?.toUpperCase()}, 
                {text: meca?.nom?.toUpperCase()}, 
                {text: meca?.prenom?.toUpperCase()},
                {text: meca?.nationality??'', alignment: 'center'},
                {text: meca?.sex==='H'?'M':'F', alignment: 'center'},
                {text: meca?.birthDate?moment.utc(meca?.birthDate).format('DD/MM/yyyy'):'', alignment: 'center'},
                {text: meca?.birthPlace?.toUpperCase(), alignment: 'center'},
                {text: meca?.proof, alignment: 'center'},
                {text: meca?.dDate?moment.utc(meca?.dDate).format('DD/MM/yyyy'):'', alignment: 'center'},
                {text: meca?.expDate?moment.utc(meca?.expDate).format('DD/MM/yyyy'):'', alignment: 'center'},
            ]
        )).concat(rotation.equipage.map(eq=>[
        {text: 'Equipage'}, 
        {text: eq.num?.toUpperCase()}, 
        {text: eq.nom?.toUpperCase()}, 
        {text: eq.prenom?.toUpperCase()},
        {text: eq.nationality??'', alignment: 'center'},
        {text: eq.sex==='H'?'M':'F', alignment: 'center'},
        {text: eq.birthDate?moment.utc(eq.birthDate).format('DD/MM/yyyy'):'', alignment: 'center'},
        {text: eq.birthPlace?.toUpperCase(), alignment: 'center'},
        {text: eq?.proof, alignment: 'center'},
        {text: eq?.dDate?moment.utc(eq?.dDate).format('DD/MM/yyyy'):'', alignment: 'center'},
        {text: eq?.expDate?moment.utc(eq?.expDate).format('DD/MM/yyyy'):'', alignment: 'center'},
    ]))

    var dd = {
        pageMargins: [ 30, 125, 30, 50 ],
        header: function(currentPage, pageCount, pageSize) { return {columns: [
            // {width:105,stack: [
            // {
            //     image:'sampleImage.jpg',
            //     width: 70,
            //     height:70,
            //     absolutePosition: {x: 25, y: 25}
            // }]},
            {stack: [
                {width:'*', text: 'Transports Express Caraïbes', bold:true, fontSize:10},
                {width:'*', text: '43, Boulevard Kennedy\n97228 Sainte-Luce\n+596 596 78 44 45 / +596 696 43 76 68' , fontSize:10},
            ],margin:[10, 25, 0, 0] }
          ],
        }},
        content: [
            { text: `Départ le ${moment.utc(rotation.date_depart).format('DD/MM/yyyy')} à ${moment(rotation.heure_depart.join(':'), 'HH:mm').format('H:mm').replace(':', 'h')} de ${rotation.from.nom.toUpperCase()} vers ${rotation.to.nom.toUpperCase()}`, alignment: 'center', fontSize: 15 },
            { text: '\nTRANSPORTS EXPRESS CARAÏBES\n\n\n',alignment: 'center', bold: true, fontSize: 22 },
            {columns: [
                {width:'*', text: 'Vessel Name:\nRegister:\nCountry of Registration:\nMMSI:\nVessel Type:\nConstruction Materiel:\nYear Build:\nColor:',  lineHeight: 2, fontSize:10},
                {width:'auto', text: 'CAPO ROSSO\n\nMARTINIQUE\n\nPOWER BOAT\nAluminium\n2019\nROSE',  lineHeight: 2, fontSize:10},
                {width:130,text:''},
                {width:'*', text: '\n\n\n\nLength:\nWidth:\nGross Tomnnage:\nTonnage UMS:',  lineHeight: 2, fontSize:10},
                {width:'auto', text: '\n\n\n\n19.16\n5.8\n39\n',  lineHeight: 2, fontSize:10},
            ]},
            {columns: [
                {width:'*', text: 'Nb Inboard Engine ... : 2',  fontSize:10},
                {width:'*', text: 'Make Engine ... : VOLVO',  fontSize:10},
                {width:'*', text: 'H.P.... : 668',  fontSize:10},
            ],margin:[10, 25, 0, 0]},
            {table: {
                widths: ['auto', 'auto', '*','*', 'auto',10,'auto', 'auto', 'auto', 'auto', 'auto'],
                headerRows: 1,
                body: tableau1
            }, margin:[-10, 25, -10, 25]},
            {columns: [
                {width:'auto', text: 'Signature\nDate :',  lineHeight: 4, fontSize:10},
                {width:'*',text:''},
                {width:'auto', text: 'Customs Officer\nDate :',  lineHeight: 4, fontSize:10, margin:[0, 0, 50, 0]},
            ]}
        ], 
        defaultStyle:{fontSize: 9}
    }
    return dd
}

export function list_passagers(billets, date, filter) {
    let content = [...new Set(billets.map(blt=>blt.rotation?.from?._id))].map(pt=>{
        var tableau1 = [
            [
                {text: 'Nom'}, 
                {text: 'Prénom', alignment: 'center'},
                {text: 'Nationalité', alignment: 'center'},
                {text: 'Sexe', alignment: 'center'},
                {text: 'Date de naissance', alignment: 'center'},
                {text: 'Lieu de naissance', alignment: 'center'},
                {text: "Pièce", alignment: 'center'},
                {text: "N° pièce ", alignment: 'center'},
                {text: "Date délivrance", alignment: 'center'},
                {text: "Validité", alignment: 'center'},
                {text: "N° billet", alignment: 'center'},
                {text: "Observation", alignment: 'center'},
            ],
        ].concat(billets.filter(blt=>blt.rotation?.from?._id===pt).map(blt=>[
                {text: blt.passager.nom?.toUpperCase()}, 
                {text: blt.passager.prenom?.at(0).toUpperCase()+blt.passager.prenom?.slice(1).toLowerCase(), alignment: 'center'},
                {text: blt.passager.nationality, alignment: 'center'},
                {text: blt.passager.sex, alignment: 'center'},
                {text: moment.utc(blt.passager.birth_date).format('DD/MM/yyyy'), alignment: 'center'},
                {text: blt.passager.birth_place, alignment: 'center'},
                {text: (blt.passager.identity_proof.type+'\n'+(blt.passager.tds_proof?.num?'Titre de séjour':'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
                {text: (blt.passager.identity_proof.num+'\n'+(blt.passager.tds_proof?.num??'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
                {text: (moment.utc(blt.passager.identity_proof.ddate).format('DD/MM/yyyy')+'\n'+(blt.passager.tds_proof?.ddate?moment.utc(blt.passager.tds_proof?.ddate).format('DD/MM/yyyy'):'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
                {text: (moment.utc(blt.passager.identity_proof.exp_date).format('DD/MM/yyyy')+'\n'+(blt.passager.tds_proof?.exp_date?moment.utc(blt.passager.tds_proof?.exp_date).format('DD/MM/yyyy'):'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
                {text: blt.num, alignment: 'center'},
                {text: ((blt.reservation.rest_to_pay>0?`reste à payer ${blt.reservation.rest_to_pay.toFixed(2)}€`:'')+'\n'+(blt.reservation.commentaire??'')+'\n'+(blt.passager?.isPmr?'PMR':'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
            ])
        )

        var tableau2 = [
            [
                {text:'', border: [false, false]},
                {text: 'Adultes', style: 'tableHeader', alignment: 'center'},
                {text: 'Enfants', style: 'tableHeader', alignment: 'center'},
                {text: 'Nourrissons', style: 'tableHeader', alignment: 'center'},
                {text: 'TOTAL', style: 'tableHeader', alignment: 'center'},
            ],
            [
                {text: 'Total', style: 'tableHeader', alignment: 'center'}, 
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie==='adulte'?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie?.includes('enfant')?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie==='nourrisson'?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).length, alignment: 'center'}
            ],
        ]

        return([{text:`Au départ de ${billets.find(blt=>blt.rotation?.from?._id===pt)?.rotation?.from?.nom} vers ${billets.find(blt=>blt.rotation?.from?._id===pt)?.rotation?.to?.nom}\n\n`, style:'subheader'},
            {table: {
                widths: ['auto', 'auto', 'auto','auto', 55, 70 ,50, 'auto',55, 55,60,'*'],
                headerRows: 1,
                body: tableau1
            }},
            {
            table: {
                widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                headerRows: 1,
                body: tableau2
            }, margin: [0, 10, 0, 15]}
        ])
    }).flat()
    
    
    
    var dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 30, 30, 30, 30 ],
        content: [
            {text: `Liste passagers réservés ${filter} du ${moment.utc(date).format('DD/MM/yyyy')}\n\n`, alignment:'center', style: 'header'},
        ].concat(content),
        styles:{
            header_footer: {fontSize:8, bold: true},
            header: {fontSize:20, bold: true},
            light_header: {fontSize:18},
            subheader: {fontSize:16, bold: true},
            tableHeader: {bold: true},
            tableTotal: {bold: true, color: '#fff'}
        },
        defaultStyle:{fontSize: 10}        
    }
    return dd
}

export function list_psg_billetvalid(billets) {
    var tableau1 = [
        [
            {text: 'Nom'}, 
            {text: 'Prénom', alignment: 'center'},
            {text: 'Nationalité', alignment: 'center'},
            {text: 'Sexe', alignment: 'center'},
            {text: 'Date de naissance', alignment: 'center'},
            {text: 'Lieu de naissance', alignment: 'center'},
            {text: "Pièce", alignment: 'center'},
            {text: "N° pièce ", alignment: 'center'},
            {text: "Date délivrance", alignment: 'center'},
            {text: "Validité", alignment: 'center'},
            {text: "N° billet", alignment: 'center'},
            {text: "Observation", alignment: 'center'},
        ],
    ].concat(billets.map(blt=>[
        {text: blt.passager.nom?.toUpperCase()}, 
        {text: blt.passager.prenom?.at(0).toUpperCase()+blt.passager.prenom?.slice(1).toLowerCase(), alignment: 'center'},
        {text: blt.passager.nationality, alignment: 'center'},
        {text: blt.passager.sex, alignment: 'center'},
        {text: moment.utc(blt.passager.birth_date).format('DD/MM/yyyy'), alignment: 'center'},
        {text: blt.passager.birth_place, alignment: 'center'},
        {text: (blt.passager.identity_proof.type+'\n'+(blt.passager.tds_proof?.num?'Titre de séjour':'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
        {text: (blt.passager.identity_proof.num+'\n'+(blt.passager.tds_proof?.num??'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
        {text: (moment.utc(blt.passager.identity_proof.ddate).format('DD/MM/yyyy')+'\n'+(blt.passager.tds_proof?.ddate?moment.utc(blt.passager.tds_proof?.ddate).format('DD/MM/yyyy'):'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
        {text: (moment.utc(blt.passager.identity_proof.exp_date).format('DD/MM/yyyy')+'\n'+(blt.passager.tds_proof?.exp_date?moment.utc(blt.passager.tds_proof?.exp_date).format('DD/MM/yyyy'):'')).split('\n').map(line => line.trim()).filter(line => line !== '').join('\n'), alignment: 'center'},
        {text: blt.num, alignment: 'center'},
        {text: blt.reservation.commentaire+(blt.passager?.isPmr? (blt.reservation.commentaire!==''?'\n':'')+'PMR':''), alignment: 'center'},
    ]))
    
    var dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 30, 30, 30, 30 ],
        content: [
            {text: `Liste passagers billets valides\n\n`, alignment:'center', style: 'header'},
            {table: {
                widths: ['auto', 'auto', 'auto','auto', 55, 70 ,50, 'auto',55, 55,60,'*'],
                headerRows: 1,
                body: tableau1
            }},
        ],
        styles:{
            header_footer: {fontSize:8, bold: true},
            header: {fontSize:20, bold: true},
            light_header: {fontSize:18},
            subheader: {fontSize:16, bold: true},
            tableHeader: {bold: true},
            tableTotal: {bold: true, color: '#fff'}
        },
        defaultStyle:{fontSize: 10}        
    }
    return dd
}

export function list_transactions(transactions, datea, dateb) {
    var tableau1 = [
        [
            {text: 'N° Transaction'}, 
            {text: 'Date', alignment: 'center'},
            {text: 'N° Réservation', alignment: 'center'},
            {text: 'Client', alignment: 'center'},
            {text: 'Montant', alignment: 'center'},
            {text: 'Mode de paiement', alignment: 'center'},
            {text: "Status paiement", alignment: 'center'},
    ]].concat(transactions.sort((a,b)=>moment.utc(a.date).isBefore(moment.utc(b.date))?-1:1).map(transaction=>[
            {text: transaction.num}, 
            {text: moment.utc(transaction.date).format('DD/MM/yyyy'), alignment: 'center'},
            {text: transaction.rsv.num, alignment: 'center'},
            {text: transaction.rsv.contact?.toUpperCase(), alignment: 'center'},
            {text: transaction.montant.toFixed(2), alignment: 'center'},
            {text: transaction.mode, alignment: 'center'},
            {text: transaction.rsv.pay_status, alignment: 'center'},
        ])
    )

    let content = [{table: {
        widths: ['*', 55, '*','*', 55, 'auto', 'auto'],
        headerRows: 1,
        body: tableau1
    }}]
    
    var dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 30, 30, 30, 30 ],
        content: [
            {text: `Liste transactions du ${moment.utc(datea).format('DD/MM/yyyy')} au ${moment.utc(dateb).format('DD/MM/yyyy')}\n\n`, alignment:'center', style: 'header'},
        ].concat(content),
        styles:{
            header_footer: {fontSize:8, bold: true},
            header: {fontSize:20, bold: true},
            light_header: {fontSize:18},
            subheader: {fontSize:16, bold: true},
            tableHeader: {bold: true},
            tableTotal: {bold: true, color: '#fff'}
        },
        defaultStyle:{fontSize: 10}        
    }
    return dd
}
