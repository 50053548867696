import React, { useContext, useState } from 'react';
import { Backdrop, Button, CircularProgress, Stack, Typography, Box, Link } from '@mui/material';
import {EventRounded, AccountBalanceWalletRounded, SellRounded, GroupsRounded, CalendarMonthRounded, DirectionsBoatFilled, Style} from '@mui/icons-material';
import { API } from '../asset/conf';
import moment from 'moment';
import { Context } from '../App';

function Home() {
  const {updateConnect} = useContext(Context)
  const [load, setLoad] = useState(false)
  const access = JSON.parse(sessionStorage.getItem('agent'))?.access

    return (
      <div style={{display:'flex', flex:1, marginBottom:30, flexDirection:'column', justifyContent:'center'}}>
        <Box display='flex' justifyContent='space-between' alignItems='center' marginX='20px'>
          {access.includes('utilisateurs')&&<Link href='/utilisateurs'>Gestion des utilisateurs</Link>}
          {/* {access.includes('tec_scan')&&<Stack direction='row' spacing={1} style={{alignSelf:'end', marginRight:'20px', marginBottom:'20px'}}>
            <Button variant='contained' href='/tec_scan'>TEC Scan</Button>
            <Button variant='contained' onClick={handleCloture} >Clôturer l'embarquement et envoyer</Button>
          </Stack>} */}
        </Box>
        
        <Stack direction='column' spacing={2} alignItems='center'>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Button variant='contained' disabled={!access.includes('reservations')} href='/reservations' style={{height:160, width:200, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
              <EventRounded sx={{ fontSize: 80, marginBottom:'15px' }} />
              <Typography variant='home_button'>réservations</Typography>
            </Button>
            <Button variant='contained' disabled={!access.includes('finances')} href='/finances' style={{height:160, width:200, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
              <AccountBalanceWalletRounded sx={{ fontSize: 80, marginBottom:'15px' }} />
              <Typography variant='home_button'>finances</Typography>
            </Button>
          </Stack>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Button variant='contained' disabled={!access.includes('organisation')} href='/organisation' style={{height:160, width:200, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
              <CalendarMonthRounded sx={{ fontSize: 80, marginBottom:'15px' }} />
              <Typography variant='home_button'>Organisation</Typography>
            </Button>
            <Button variant='contained' disabled={!access.includes('billets_valides')} href='/billets_valides' style={{height:160, width:200, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
              <Style sx={{ fontSize: 80, marginBottom:'15px' }} />
              <Typography variant='home_button'>Billets valides</Typography>
            </Button>
          </Stack>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Button variant='contained' disabled={!access.includes('clients')} href='/clients' style={{height:160, width:200, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
              <GroupsRounded sx={{ fontSize: 80, marginBottom:'15px' }} />
              <Typography variant='home_button'>clients</Typography>
            </Button>
            <Button variant='contained' disabled={!access.includes('promo')} href='/promo' style={{height:160, width:200, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
              <SellRounded sx={{ fontSize: 80, marginBottom:'15px' }} />
              <Typography variant='home_button'>tarifs & promo</Typography>
            </Button>
          </Stack>
          {access.includes('embarquement')&&<Button variant='contained' href='/embarquement' style={{height:160, width:400, borderRadius:15, flexDirection:'column', justifyContent:'flex-end', alignSelf:'center'}}>
            <DirectionsBoatFilled sx={{ fontSize: 80, marginBottom:'15px' }} />
            <Typography variant='home_button'>embarquement</Typography>
          </Button>}
        </Stack>
        
        <Backdrop open={load}><CircularProgress/></Backdrop>
      </div>
    );
  }
  
  export default Home;