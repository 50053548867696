import { AppBar, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, MenuItem, TextField, Toolbar, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { API } from "../asset/conf";
import { Context } from "../App";
import moment from "moment";
import { Close, Search } from "@mui/icons-material";
import RotatDays from "./rotat-days";


export default function BilletForm(props) {
    const { updateConnect } = useContext(Context)
    const [load, setLoad] = useState(true)
    const [dates, setDates] = useState([])
    const [dispo_rotations, setDispoRotations] = useState([])
    const [ports, setports] = useState([])
    const [clients, setclients] = useState([])
    const [open, setOpen] = useState(false)

    const [from,setfrom] =useState(props.billet?.rotation?.from?._id??'')
    const [to, setTo] = useState(props.billet?.rotation?.to?._id??'')
    const [date, setDate] = useState(moment.utc(props.billet?.rotation?.date_depart).format('yyyy-MM-DD')??'')
    const [rotation, setRotation] = useState(props.billet?.rotation?._id??'')
    const [passager, setPassager] = useState(props.billet?.passager?._id??'')
    const [facture_update, setfacture] = useState(props.billet?.reservation?.updates>0)
    const [montant,setMontant] = useState(0)

    function searchRotations(date, from, to) {
        setLoad(true); setDates([])
        fetch(API.concat(`/rotations/search/${JSON.stringify({ from, to, date})}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async response=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    setDispoRotations(json)
                    const start = moment(date).add(moment().diff(moment(date), 'days')>-6 ? moment().diff(moment(date), 'days') : -6, 'days')
                    let list = []
                    for (let i = 0; i < 14; i++) {
                        const dt = start.add(i===0 ? 0 : 1, 'days').format('yyyy-MM-DD')
                        list.push(dt)
                    }
                    setDates(list)
                }
                setLoad(false)
            }
        })
    }

    function submit() {
        setLoad(true)
        const info = {rotation, passager, facture_update, montant:!isNaN(Number(montant))?Number(montant):0}

        fetch(API.concat(`/billet/${props.billet?._id}/update`), {method: 'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify(info)
        }).then(async response=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {blt, rtn, rsv ,psg} = await response.json()
                    rtn.from=ports.find(pt=>pt._id===rtn.from)
                    rtn.to=ports.find(pt=>pt._id===rtn.to)
                    blt.passager=psg; blt.reservation=rsv; blt.rotation=rtn
                    props.addRsv(blt)
                    props.close()
                }
                setLoad(false)
            }
        })
    }

    function handleSubmit() { 
        if (props.billet?.passager?.categorie!==clients.find(cl=>cl._id===passager)?.categorie) {
            alert(`Attention!\nVous ne pouvez pas attribuer un billet ${props.billet?.passager?.categorie} à un ${clients.find(cl=>cl._id===passager)?.categorie}`)
        } else {
            const no_pmr = dispo_rotations.some(dr=>dr._id===rotation&&dr.dispo_pmr<1)
            if(no_pmr&&clients.find(cl=>cl._id===passager).isPmr){
                setOpen(true)
            }
            else submit()
        }
    }

    useEffect(()=>{

        function getPorts() {
            fetch(API.concat(`/ports`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }
            }).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setports(json)
                    }
                    setLoad(false)
                }
            })
        }

        function getClients() {
            setLoad(true)
            fetch(API.concat('/passagers'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setclients(json)
                    }
                    setLoad(false)
                }
            })
        }

        getPorts(); getClients()

    }, [updateConnect])

    useEffect(()=>{}, [rotation])

    return(
        <Box sx={{display:'flex', flexDirection:'column', marginBottom:'30px'}}>
            <AppBar sx={{position:'relative'}}>
                <Toolbar sx={{display:'flex', flexDirection:'row'}}>
                    <Typography variant="h3" align="center" sx={{flex:1}}>Modifier un Billet</Typography>
                    <IconButton onClick={props.close}><Close/></IconButton>
                </Toolbar>
            </AppBar>
            <Box display='flex' justifyContent='center' height='250px' sx={{backgroundColor:'transparent'}}>
                <Box display='flex' flexDirection='column' sx={{marginX:'20px', width:'80%', minWidth:'700px', alignSelf:'center'}}>
                    <Box sx={{display:'flex', alignItems:'center', borderRadius:'0px 8px 8px 8px', backgroundColor:'primary.light', overflow:'hidden', height:'90px', width:'100%'}}>
                        <TextField value={from} onChange={(e)=>setfrom(e.target.value)} select fullWidth label='Départ' 
                        sx={{minWidth:'120px', "& fieldset": { border: 'none' }}}>
                            {ports.map(port=>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography textTransform='capitalize'>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>)}
                        </TextField>
                        <Divider orientation="vertical" variant="middle" sx={{height:'60%'}}/>
                        <TextField value={to} onChange={e=> setTo(e.target.value)} select fullWidth label='Arrivée' 
                        sx={{minWidth:'120px', "& fieldset": { border: 'none' }}}>
                            {ports.map(port=>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography textTransform='capitalize'>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>)}
                        </TextField>
                        <Divider orientation="vertical" />
                        <TextField type="date" value={date} onChange={(e)=>setDate(e.target.value)} inputProps={{min: new Date().toISOString().split("T")[0]}}
                        fullWidth sx={{minWidth:'100px', "& fieldset": { border: 'none' }}} label='Date départ' InputLabelProps={{shrink: true}}/>
                        <IconButton 
                        onClick={()=>searchRotations(date, from, to)}
                        disabled={from===''||to===''||date===''}
                        sx={{backgroundColor:'primary.main', width:'80px', borderRadius:0, height:'100%'}}>
                            <Search style={{fontSize:"32px", color:'white'}}/>
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            <Box display='flex' flexDirection='column' sx={{ minWidth:'700px', alignSelf:'center'}}>
                {dates.length>0&&<RotatDays dates={dates} date={date} rotation={rotation} setdate={(date)=>setDate(date)} setrotation={(rotat)=>setRotation(rotat)} ports={ports} is_edit_billet={true} rotations={dispo_rotations}/>}
                <Box>
                    <Typography>Sélectionner un client déjà existant</Typography>
                    <TextField select value={passager} onChange={(e)=>setPassager(e.target.value)} sx={{width:'80%', minWidth:'200px'}}>
                        {clients.map(client=>
                            <MenuItem key={client._id} value={client._id} style={{textTransform:'capitalize'}}>{client.nom+' '+client.prenom}</MenuItem>
                        )}
                    </TextField>
                    {/* <Divider orientation="horizontal" variant="middle" sx={{marginY:'30px'}}/>
                    <ClientForm edit={passager!==''} client={clients.find(client=> client._id===passager)} add={(edited)=> setclients(old=> {
                        const nclient=[...old]; if(passager!==''){nclient[nclient.findIndex(client=> client._id===passager)]=edited}else{nclient.push(edited); setPassager(edited._id)}; return nclient})} 
                    />                                 */}
                </Box>
            </Box>
            
            <FormControlLabel sx={{alignSelf:'end', marginTop:'10px', marginRight:3}} control={<Checkbox checked={facture_update} onChange={()=>setfacture(old=>!old)}/>} label="Facturer la modification" />
            <Box display='flex' flexDirection='column' sx={{minWidth:'90px', width:'20%', alignSelf:'end', marginRight:3, alignItems:'end'}}>
                <Typography align="center">Frais de modification additifs(en plus des 5€ si 2eme ou plus modification)</Typography>
                <TextField size="small" sx={{width:'30%', minWidth:'80px', marginTop:1}} required label="Montant" type="number"
                InputProps={{ inputProps: { min: 0, step:5 } }} value={montant} onChange={(e)=>{if(/^\d+([.,]\d{1,2})?$/.test(e.target.value)|| e.target.value==='') setMontant(e.target.value)}}/>
            </Box>

            <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{alignSelf:'end', marginRight:3, marginTop:2}}
            color="primary"
            disabled={rotation===''}
            >
                {load && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } {props.edit?'Modifier':'Valider'}
            </Button>

            <Dialog open={open}>
                <DialogTitle>Il ne reste plus de place PMR sur cette traversée</DialogTitle>
                <DialogContent>Souhaitez-vous mettre ce billet sur liste d'attente?</DialogContent>
                <DialogActions>
                    <Button variant="clear" onClick={()=>{setOpen(false)}}>Annuler</Button>
                    <Button variant="contained" onClick={()=>{setOpen(false); submit()}}>Mettre sur liste d'attente</Button>
                </DialogActions>
            </Dialog>
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Box>
    )
}