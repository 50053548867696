import { Backdrop, Box, Button, CircularProgress, Dialog, Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Scanner } from '@yudiel/react-qr-scanner';
// import { API } from "../asset/conf";
// import moment from "moment";
// import { Context } from "../App";
import PayForm from "./pay-mode-form";
import { WarningTwoTone } from "@mui/icons-material";

export default function TecScan(props) {
    // const {updateConnect} = useContext(Context)
    const [load, setLoad] = useState(true)
    const [scan, setScan] = useState(false)
    // const [open, setOpen] = useState(false)
    // const [action, setAction] = useState('')
    // const [rotation, setRotation] = useState()
    // const [dispo_rtn, setDispoRtn] = useState([])
    // const [billet, setBillet] = useState()
    // const [payments, setPay] = useState([])
    // const [embarques, setEmbarques] = useState(0)
    // const [rest_to_pay, setTopay] = useState(0)

    function handleScan(data){
        const result = data[0]?.rawValue; 
        // setScan(data[0]?.rawValue? false : true)
        // setLoad(data[0]?.rawValue? true : false)
        props.setScanned(result)
        // fetch(API.concat(`/embarquement/billets/${result}`), {method:'GET', headers:{
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        // }}).then(async(response)=>{
        //     const token = response.headers.get('x-auth-token')
        //     if (response.status === 401) {
        //         sessionStorage.clear()
        //         updateConnect(false)
        //     } else {
        //         if (token && token !== sessionStorage.getItem('token') && token !== '') {
        //             sessionStorage.setItem('token', response.headers.get('x-auth-token'))
        //         }
        //         if (response.status===200) {
        //             const {blt, psg, rsv} = await response.json()
        //             blt.passager = psg; blt.reservation = rsv
        //             setBillet(blt); setPay(rsv.payments)
        //             setTopay(rsv.total+(rsv.updates>1?(rsv.updates-1)*5:0)-rsv.payments.reduce((tt, p)=>tt+p.montant,0))
        //             manage_dialog(true, 'valide')
        //         }
        //     }
        // }) 
    }

    // async function handleValid() {
    //     setLoad(true)
    //     const scanned = !billet.isScaned
    //     fetch(API.concat(`/embarquement/billets/${billet._id}`), {method:'PATCH', headers:{
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    //         }, body: JSON.stringify({scan: scanned, pay: payments, rtn: rotation._id})
    //     }).then(async(response)=>{
    //         const token = response.headers.get('x-auth-token')
    //         if (response.status === 401) {
    //             sessionStorage.clear()
    //             updateConnect(false)
    //         } else {
    //             if (token && token !== sessionStorage.getItem('token') && token !== '') {
    //                 sessionStorage.setItem('token', response.headers.get('x-auth-token'))
    //             }
    //             if (response.status===200) {
    //                 setEmbarques(old=>old+(scanned?1:-1))
    //             }
    //             setLoad(false); manage_dialog(false, '')
    //         }
    //     }) 
    // }

    // async function manage_dialog(state, action) {
    //     setOpen(state)
    //     setAction(action)
    //     if (!state) {
    //         setBillet(); setPay([])
    //     }
    // }

    return(
        <Box display='flex' flexDirection='column' sx={{flex:1, marginY:'30px'}}>
            <Typography variant="h3" align="center">Scan de billets</Typography>
            {!scan&&<Button variant="contained" sx={{alignSelf:'center', marginTop:'100px'}} onClick={()=> setScan(true)}>Scanner</Button>}
            {scan&&<Scanner styles={{container:{width:'300px', height:'300px', alignSelf:'center', marginTop:'60px'}}} scanDelay={500} onScan={handleScan} />}
            
            {/* <Dialog open={open} fullWidth maxWidth='sm'>
                {action==='valide'&&<Box display='flex' flexDirection='column' paddingBlock='30px' paddingInline='20px'>
                    <Typography variant="h3" marginBottom='20px' textTransform='capitalize' align="center">Billet {billet.passager.categorie} N°{billet.num}</Typography>
                    <Typography variant="subtitle1" marginBottom='20px' textTransform='capitalize'>Passager: {billet.passager.nom+' '+billet.passager.prenom}</Typography>
                    {rest_to_pay>0&&<Stack direction='row' spacing={2} alignItems='center' marginY='20px' alignSelf='center'>
                        <WarningTwoTone color='red' sx={{ fontSize: 50 }}/>
                        <Typography variant="h2" color='red.main' textTransform='capitalize'>à payer: {rest_to_pay.toFixed(2)}€</Typography>
                    </Stack>}
                    {rest_to_pay>0&&<Divider variant="middle" sx={{marginX:'15%'}}/>}
                    {rest_to_pay>0&&<Typography variant="h4" align="center" marginY='20px'>Paiement</Typography>}
                    {rest_to_pay>0&&<PayForm payments={payments} setPay={setPay}/>}
                    <Stack direction='row' spacing={2} alignSelf='end'>
                        {/* <Button variant="outlined" onClick={()=>{manage_dialog(false, '')}}>annuler</Button> 
                        <Button variant="contained" onClick={()=>{handleValid();manage_dialog(false, '')}}>continuer</Button>
                    </Stack>
                </Box>}
            </Dialog> */}
            {/* <Backdrop open={load}><CircularProgress/></Backdrop> */}
        </Box>
    )
}