import { Accordion, AccordionDetails, AccordionSummary, AppBar, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Menu, MenuItem, Stack, TextField, Toolbar, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { API } from "../asset/conf";
import { Context } from "../App";
import moment from "moment";
import { Add, Close, ExpandMore, Remove, Search } from "@mui/icons-material";
import { theme } from "../App";
import RotatDays from "./rotat-days";
import ClientForm from "./client-form";
import PayForm from "./pay-mode-form";
import ClientSearch from "./autocomplet-search";


export default function ReservationForm(props) {
    const { updateConnect } = useContext(Context)
    const [load, setLoad] = useState(true)
    const [dates, setDates] = useState([])
    const [dispo_rotations, setDispoRotations] = useState([])
    const [ports, setports] = useState([])
    const [tarifs, setTarifs] = useState([])
    const [promos, setpromos] = useState([])
    const [clients, setclients] = useState([])
    const [no_pmr, setPmr] = useState([])

    const [a_r, setAR] = useState(true)
    const [is_journee, setJournee] = useState(false)
    const [from,setfrom] =useState('')
    const [to, setTo] = useState('')
    const [adate, setaDate] = useState(moment().format('yyyy-MM-DD'))
    const [rdate, setrDate] = useState('')
    const [cat_passengers, setpassengers] = useState([{label:'Adulte (+12 ans)', count:1}, {label:'Enfant B (5-12 ans)', count:0}, {label:'Enfant A (1-4 ans)', count:0}, {label:'Nourrisson (-1 ans)', count:0}])
    const [rotations, setRotations] = useState([])
    const [passagers, setPassagers] = useState([])
    const [price, setPrice] = useState(props.billet?.reservation?.total??0)
    const [promo, setPromo] = useState('')
    const [com, setCom] = useState('')
    const [mail, setmail]=useState('')
    const [tel, setTel]=useState('')
    const [contact, setContact] = useState('') 
    const [address1, setAddr1] = useState('')
    const [address2, setAddr2] = useState('')
    const [zipcode, setZip] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [payments, setPay] = useState([{date: moment().format('yyyy-MM-DD'), montant: 0, is_acompte: false, ref: '', mode: ''}])
    const [facture_update, setfacture] = useState(false)

    const [expanded, setExpanded] = useState('panel1');
    const [nested_expanded, setnestedExpanded] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    //useMemo pour total updated when passager and calculate if have one rotation selected

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        fill_passagers()
    };

    const handleChange = (panel, isExpanded, isnested) => {
        if (isnested) {
            setnestedExpanded(isExpanded ? panel : false)
        } else setExpanded(isExpanded ? panel : false);
    };

    function fill_passagers() {
        if (passagers.length<=0) {
            setPassagers(new Array(cat_passengers.reduce((count, current)=>count+current.count,0)).fill(''))
        } else if (passagers.length!==cat_passengers.reduce((count, current)=>count+current.count,0)) {
            setPassagers(old=>{
                let psgs = old.filter(psg=>psg!=='')
                if (psgs.length>cat_passengers.reduce((count, current)=>count+current.count,0)) {
                    psgs.splice(cat_passengers.reduce((count, current)=>count+current.count,0))
                    return psgs
                } else {
                    psgs = psgs.concat(new Array(cat_passengers.reduce((count, current)=>count+current.count,0)-psgs.length).fill(''))
                    return psgs
                }
            })
        }
        if (clients.length<=0) {
            setLoad(true)
            fetch(API.concat(`/passagers/${JSON.stringify({isWeb: false})}`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setclients(json)
                    }
                    setLoad(false)
                }
            })
        }
        setnestedExpanded(0)
    }

    function searchRotations(date, from, to) {
        setLoad(true); setDates([])
        fetch(API.concat(`/rotations/search/${JSON.stringify({ from, to, date:moment.utc(date).add(-6,'day').format('yyyy-MM-DD')})}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async response=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    console.log(json)
                    setDispoRotations(json)
                    const start = moment(date).add(moment().diff(moment(date), 'days')>-6 ? moment().diff(moment(date), 'days') : -6, 'days')
                    let list = []
                    for (let i = 0; i < 14; i++) {
                        const dt = start.add(i===0 ? 0 : 1, 'days').format('yyyy-MM-DD')
                        list.push(dt)
                    }
                    setDates(list)
                }
                setLoad(false)
            }
        })
    }

    function get_total() {
        const select_rotation_tarifs = [...new Set(rotations.map(rt=>rt.tarifs??[]).flat())]
        const tfs = select_rotation_tarifs.length>0 ? tarifs.filter(tf=>select_rotation_tarifs.includes(tf._id)) : 
        tarifs.filter(tf=>tf.is_journee===is_journee)
        
        const prix = passagers.length>=10 ? price : passagers.reduce((tt, current)=>tt+(tfs.find(tf=>tf.isA_R===a_r&&Boolean(tf.is_journee)===is_journee&&clients.find(cl=>cl._id===current)?.categorie?.toLowerCase().includes(tf.categorie))?.prix??0),0)
        const pmt = promos.find(prom=>prom.code===promo)
        const remise = pmt ?( pmt.is_in_percent? prix*(pmt?.reduction??0)/100 : pmt?.reduction??0) : 0
        return Number((prix-remise).toFixed(2))
    }

    function submit() {
        setLoad(true); 
        const payed = payments.reduce((total, current)=>total+current.montant,0)
        const user = JSON.parse(sessionStorage.getItem('agent'))
        const rsv = {
            isA_R: a_r,
            is_journee: is_journee,
            date_depart: new Date(adate),
            date_retour: new Date(rdate),
            rotations: rotations,
            passagers: passagers,
            total: price,
            rest_to_pay: price-payed,
            mail:mail,
            tel:tel,
            contact:contact,
            address1:address1,
            address2:address2,
            zipcode:zipcode,
            city:city,
            country:country,
            pay_status: payed<=0&&price>0 ?'en attente': payed<price ? 'Acompte versé':'payé',
            promo_code: promo,
            commentaire: com,
            payments: payments.filter(pay=>Number(pay.montant)>0).map(pay=>{pay.date=new Date(pay.date); pay.by=pay.by??user.where; pay.montant=Number(pay.montant); return pay})
        }

        fetch(API.concat(props.edit? `/reservation/${props.billet?.reservation?._id}`:'/reservations'), {method:props.edit? 'PATCH':'POST', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify(rsv)
        }).then(async response=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {rsv, rtn, blt, psg} = await response.json()
                    Promise.all(rtn.map(item=>{return new Promise((resolve, reject) => {
                        item.from = ports.find(p=> p._id===item?.from)
                        item.to = ports.find(p=> p._id===item?.to)
                        resolve(item)
                    })})).then(rotations=>{
                        Promise.all(blt.map(item=>{return new Promise((resolve, reject) => {
                            item.passager = psg.find(p=> p._id===item.passager)
                            item.rotation = rotations.find(r=>r._id===item.rotation); 
                            item.reservation = rsv
                            resolve(item)
                        })})).then(results=>{
                            props.addRsv(results)
                            props.close()
                        })
                    })
                }else props.close()
                setLoad(false)
            }
        })
    }

    function handleSubmit() {
        const pmr = passagers.reduce((tt, psg)=>tt+(clients.find(cl=>cl._id===psg).isPmr?1:0),0)
        const no_pmr = dispo_rotations.filter(dr=>rotations.includes(dr._id)&&dr.dispo_pmr<pmr)
        if(no_pmr.length>0){
            setPmr(no_pmr)
        }
        else submit()
    }

    useEffect(()=>{
        if(props.edit){
            fill_passagers()
            let categories = [{label:'Adulte (+12 ans)', count:0}, {label:'Enfant B (5-12 ans)', count:0}, {label:'Enfant A (1-4 ans)', count:0}, {label:'Nourrisson (-1 ans)', count:0}]
            props.passagers?.forEach(psg => {
                categories.forEach(cat=> {
                    if(cat.label.toLowerCase().includes(psg.categorie)){
                        cat.count+=1
                    }
                }) 
            })
            setAR(props.billet?.reservation?.isA_R)
            setJournee(props.billet?.reservation?.is_journee)
            setfrom('')
            setTo('')
            setaDate(moment.utc(props.billet?.reservation?.date_depart).format('yyyy-MM-DD')?? '')
            setrDate(moment.utc(props.billet?.reservation?.date_retour).format('yyyy-MM-DD')?? '')
            setpassengers(categories)
            setRotations(props.billet?.reservation?.rotations??[])
            setPassagers(props.passagers?.map(psg=> psg._id)??[])
            setPromo(props.billet?.reservation?.promo_code??'')
            setCom(props.billet?.reservation?.commentaire??'')
            setmail(props.billet?.reservation?.mail??'')
            setTel(props.billet?.reservation?.tel??'')
            setContact(props.billet?.reservation?.contact??'')
            setAddr1(props.billet?.reservation?.address1??'')
            setAddr2(props.billet?.reservation?.address2??'')
            setZip(props.billet?.reservation?.zipcode??'')
            setCity(props.billet?.reservation?.city??'')
            setCountry(props.billet?.reservation?.country??'')
            setExpanded('panel3')
            setPay(props.billet?.reservation?.payments??[{date: moment().format('yyyy-MM-DD'), montant: 0, is_acompte: false, ref: '', mode: ''}])
        }
    }, [props.edit, props.is_billet, props.billet, props.passagers, props.billet?.reservation])

    useEffect(()=>{
        if (cat_passengers.reduce((tt, cat)=>tt+(cat.label.includes('Nourrisson')? 0: cat.count),0)<10&& passagers.length>0&&!props.edit) {
            setPrice(get_total())
        }
    }, [passagers])

    useEffect(()=>{
        function getTarifs() {
            fetch(API.concat(`/tarifs`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }
            }).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setTarifs(json)
                    }
                }
            })
        }

        function getPromos() {
            fetch(API.concat(`/promos/${JSON.stringify({$and: [{start_date: {$lte: new Date(moment().format('yyyy-MM-DD'))}}, { end_date: {$gte: new Date(moment().format('yyyy-MM-DD'))}}]})}`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }
            }).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setpromos(json)
                    }
                }
            })
        }

        function getPorts() {
            fetch(API.concat(`/ports`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }
            }).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setports(json)
                    }
                    setLoad(false)
                }
            })
        }

        getTarifs(); getPromos(); getPorts()

    }, [updateConnect])

    return(
        <Box sx={{display:'flex', flexDirection:'column', marginBottom:'30px'}}>
            <AppBar sx={{position:'relative'}}>
                <Toolbar sx={{display:'flex', flexDirection:'row'}}>
                    <Typography variant="h3" align="center" sx={{flex:1}}>{props.edit? 'Modifier':'Enregistrer'} {props.edit&&props.is_billet?'un Billet':'une Réservation'}</Typography>
                    <IconButton onClick={props.close}><Close/></IconButton>
                </Toolbar>
            </AppBar>
            <Box display='flex' justifyContent='center' height='250px' sx={{backgroundColor:'transparent'}}>
                <Box display='flex' flexDirection='column' sx={{marginX:'20px', width:'80%', minWidth:'700px', alignSelf:'center'}}>
                    {!props.edit&&<Box display='flex' flexDirection='row' alignSelf='flex-start' alignItems='center' 
                    sx={{backgroundColor:'primary.main', overflow:'hidden', borderRadius:'6px 6px 0px 0px'}}>
                        <Button variant="contained" onClick={()=> setAR(true)} style={{backgroundColor:a_r ? theme.palette.primary.light : theme.palette.primary.main, borderRadius:'6px 0px 0px 0px'}} >Aller/Retour</Button>
                        <Button variant="contained" onClick={()=> setAR(false)} sx={{backgroundColor: a_r ? "primary.main" : "primary.light", borderRadius:'0px 6px 0px 0px'}}>Aller Simple</Button>
                    </Box>}
                    <Box sx={{display:'flex', alignItems:'center', borderRadius:'0px 8px 8px 8px', backgroundColor:'primary.light', overflow:'hidden', height:'90px', width:'100%'}}>
                        <TextField value={from} onChange={(e)=>setfrom(e.target.value)} select fullWidth label='Départ' 
                        sx={{minWidth:'120px', "& fieldset": { border: 'none' }}}>
                            {ports.sort((a,b)=>a.commune.toLowerCase()==='marin'?-1:b.commune.toLowerCase()==='marin'?1:a.commune<b.commune?-1:1).map(port=>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography textTransform='capitalize'>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>)}
                        </TextField>
                        <Divider orientation="vertical" variant="middle" sx={{height:'60%'}}/>
                        <TextField value={to} onChange={e=> setTo(e.target.value)} select fullWidth label='Arrivée' 
                        sx={{minWidth:'120px', "& fieldset": { border: 'none' }}}>
                            {ports.sort((a,b)=>a.commune.toLowerCase()==='marin'?-1:b.commune.toLowerCase()==='marin'?1:a.commune<b.commune?-1:1).map(port=>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography textTransform='capitalize'>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>)}
                        </TextField>
                        <Divider orientation="vertical" />
                        <TextField type="date" value={adate} onChange={(e)=> {setaDate(e.target.value); if (rdate!==''&&new Date(e.target.value)>new Date(rdate)) {
                            setrDate('')
                        }}} inputProps={{min: new Date().toISOString().split("T")[0]}}
                        fullWidth sx={{minWidth:'100px', "& fieldset": { border: 'none' }}} label='Aller' InputLabelProps={{shrink: true}}/>
                        {a_r&&<Divider orientation="vertical" variant="middle" sx={{height:'60%'}}/>}
                        {a_r&&<TextField type="date" value={rdate} onChange={(e)=> {setrDate(e.target.value); if (adate!==''&&new Date(e.target.value)<new Date(adate)) {
                            setaDate('')
                        }}} inputProps={{min: new Date(adate).toISOString().split("T")[0]}}
                        fullWidth sx={{minWidth:'100px', "& fieldset": { border: 'none' }}} label='Retour' InputLabelProps={{shrink: true}}/>}
                        <Divider orientation="vertical" variant="middle"/>
                        {!props.edit&&<Button
                        style={{color:'black', textTransform:'none', whiteSpace:'pre-line'}}
                        sx={{minWidth:'120px', width:'100%', height:'100%', "& fieldset": { border: 'none' }}}
                        aria-controls={open ? 'Passagers' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        >
                            <Typography><span style={{fontWeight:'500'}}>Passagers</span><br/>
                            {cat_passengers.reduce((all, current)=>all+current.count,0)} passager(s)
                            </Typography>
                        </Button>}
                        <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'Passagers',
                        }}
                        >
                            {cat_passengers.map(cat_passenger=>
                            <MenuItem key={cat_passenger.label}>
                                <IconButton onClick={()=>setpassengers(old=> old.map(p=> {if(p.label===cat_passenger.label){
                                    p.count+=1
                                }return p }))}><Add/></IconButton>
                                {cat_passenger.count}
                                <IconButton onClick={()=>setpassengers(old=> old.map(p=> {if(p.label===cat_passenger.label&&p.count>0){
                                    p.count-=1
                                }return p }))}><Remove/></IconButton>
                                <span style={{textTransform:'capitalize'}}>{cat_passenger.label}</span>
                            </MenuItem>)}
                        </Menu>
                        <IconButton 
                        onClick={()=>searchRotations(adate, from, to)}
                        disabled={from===''||to===''||adate===''||(a_r&&rdate==='')||cat_passengers.reduce((all, current)=>all+current.count,0)<=0}
                        sx={{backgroundColor:'primary.main', width:'80px', borderRadius:0, height:'100%'}}>
                            <Search style={{fontSize:"32px", color:'white'}}/>
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            <Box display='flex' flexDirection='column' sx={{ minWidth:'700px', alignSelf:'center'}}>
                {dates.length>0&&<Accordion expanded={expanded === 'panel1'} onChange={(event, isExpanded)=>{searchRotations(adate, from, to); handleChange('panel1', isExpanded)}}>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Traversée Aller
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RotatDays dates={dates} date={adate} setJournee={e=>setJournee(e)}
                        setdate={(date)=>setaDate(date)} a_r={a_r} 
                        setrotation={(rotat)=>setRotations(old=>{old[0]=rotat; return old})} 
                        passagers={cat_passengers} ports={ports} tarifs={tarifs} is_journee={is_journee} rotations={dispo_rotations} selected={rotations}
                        next={()=>{if(a_r){searchRotations(rdate, to, from)} else{fill_passagers()} handleChange(a_r ?'panel2':'panel3', true)}}/>
                    </AccordionDetails>
                </Accordion>}
                {dates.length>0&&a_r&&<Accordion expanded={expanded === 'panel2'} onChange={(event, isExpanded)=>{searchRotations(rdate, to, from); handleChange('panel2', isExpanded)}} disabled={rotations.length<1}>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Traversée Retour
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RotatDays dates={dates} date={rdate} a_r={a_r} setJournee={e=>setJournee(e)}
                        setdate={(date)=>setrDate(date)} setrotation={(rotat)=>setRotations(old=>{old[1]=rotat; return old})} 
                        passagers={cat_passengers} ports={ports} tarifs={tarifs} is_journee={is_journee} rotations={dispo_rotations} selected={rotations}
                        next={()=>{fill_passagers(); handleChange('panel3', true)}}/>
                    </AccordionDetails>
                </Accordion>}
                {!props.edit&&<Accordion expanded={expanded === 'panel3'} onChange={(event, isExpanded)=> {fill_passagers(); handleChange('panel3', isExpanded)}} disabled={a_r ? rotations.length<2 : rotations.length<1}>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Informations Passager(s)
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {passagers.map((passager, index) => 
                        <Accordion key={index} expanded={nested_expanded === index} onChange={(event, isExpanded)=> handleChange(index, isExpanded, true)}>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    Passager {index+1}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Rechercher un client déjà existant</Typography>
                                <ClientSearch clients={clients} value={passager?? ''} setClient={psg=>{setPassagers(old=>{const np=[...old]; np[index]=psg._id; return np}) 
                                    if(index===passagers.length-1){setnestedExpanded(false); setExpanded(false)}else setnestedExpanded(index+1)}} style={{width:'80%', minWidth:'200px'}} />
                                {/* <TextField select  onChange={} 
                                sx={{width:'80%', minWidth:'200px'}} >
                                    {clients.map(client=>
                                        <MenuItem key={client._id} value={client._id} style={{textTransform:'capitalize'}}>{client.nom+' '+client.prenom}</MenuItem>
                                    )}
                                </TextField> */}
                                <Divider orientation="horizontal" variant="middle" sx={{marginY:'30px'}}/>
                                <ClientForm key={passager} edit={passager!==''} client={clients.find(client=> client._id===passager)} add={(edited)=> setclients(old=> {
                                    const nclient=[...old]; if(passager!==''){nclient[nclient.findIndex(client=> client._id===passager)]=edited}else{nclient.push(edited); setPassagers(old=>{const np=[...old]; np[index]=edited._id; return np})}; return nclient})} 
                                close={()=> {if(index===passagers.length-1){setnestedExpanded(false); setExpanded(false)}else setnestedExpanded(index+1)}} />                                
                            </AccordionDetails>
                        </Accordion>)}
                    </AccordionDetails>
                </Accordion>}
            </Box>

            {passagers.length>0&&!passagers.includes('')&&<Stack direction='row' spacing={5} sx={{alignItems:'center', margin:'30px', alignSelf:'center', width:'60%'}}>
                <TextField multiline rows={4} type="text" label="Commentaire" sx={{width:'60%', minWidth:'400px'}} value={com} onChange={e=>setCom(e.target.value)}/>
                <TextField label="Code promo" value={promo} onChange={e=>setPromo(e.target.value)} sx={{minWidth:'150px', width:'40%'}}/>
            </Stack>}

            <Box display='flex' flexDirection='column' component='form' onSubmit={e=>{e.preventDefault();handleSubmit()}}>
            <Stack direction='column' spacing={2} sx={{ minWidth:'700px', width:'60%', alignSelf:'center', marginTop:3}}>
                <Typography variant="subtitle1" marginX={3}>Informations de contact et de facturation</Typography>
                <TextField type="name" label="Nom complet" required fullWidth value={contact} onChange={e=>setContact(e.target.value)}/>
                <Stack direction='row' spacing={2} alignItems='center' marginX={3}>
                    <TextField type="email" label="Adresse e-mail de contact" placeholder="exemple@abc123.com" required fullWidth
                    value={mail} onChange={e=>setmail(e.target.value)} sx={{minWidth:'250px', margin:'20px', alignSelf:'center'}}/>
                    <TextField fullWidth sx={{minWidth:'200px'}} label="Tél" type="tel"
                    value={tel} onChange={(e)=>setTel(e.target.value)}/>
                </Stack>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <TextField type="address" label="Adresse postale" fullWidth value={address1} onChange={e=>setAddr1(e.target.value)}/>
                    <TextField label="Complément d'adresse" fullWidth value={address2} onChange={e=>setAddr2(e.target.value)}/>
                </Stack>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <TextField type="zip-code" label="Code postale" required value={zipcode} onChange={e=>setZip(e.target.value)} sx={{width:'15%', minWidth:'120px'}}/>
                    <TextField type="city" label="Ville" required fullWidth value={city} onChange={e=>setCity(e.target.value)}/>
                    <TextField label="Pays" required fullWidth value={country} onChange={e=>setCountry(e.target.value)}/>
                </Stack>
            </Stack >

            {rotations.length>0&&passagers.length>0&&!load&&<Stack direction='row' spacing={2} alignItems='center' style={{alignSelf:'end', margin:'10px 20px 20px 0'}}>
                <Typography variant="subtitle1">Total:</Typography>
                <TextField type="number" size="small" value={price} onChange={e=>{if(!isNaN(Number(e.target.value)))setPrice(Number(e.target.value))}}/>
            </Stack>}
            {props.billet?.reservation?.updates>1&&<Typography variant="subtitle1" style={{alignSelf:'end', margin:'0 20px 20px 0'}}>
                Frais de modification: {((props.billet?.reservation?.updates-1)*5).toFixed(2)}€
            </Typography>}
            {rotations.length>0&&passagers.length>0&&<><Typography variant="h4" align="center" marginY='20px'>Paiement</Typography>
            <PayForm payments={payments} setPay={setPay} passagers={clients.filter(clt=>passagers.includes(clt._id))}/></>}
            
            {props.edit&&props.billet?.reservation?.updates>0&&
            <FormControlLabel sx={{alignSelf:'end', marginTop:'10px'}} control={<Checkbox checked={facture_update} onChange={()=>setfacture(old=>!old)} color="primary" />} label="Facturer la modification" />}

            <Button type="submit" variant="contained" style={{alignSelf:'end', marginRight:'20px'}}
            disabled={passagers.length<=0||passagers.includes('')}>
                {load && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } {props.edit?'Modifier':'Valider'}
            </Button>
            </Box>

            <Dialog open={no_pmr.length>0}>
                <DialogTitle>Il ne reste plus assez de place PMR sur {no_pmr.length>1?'les traversées':'la traversée'} {no_pmr.map(rtn=> ports.find(p=>p._id===rtn.from).nom+' -> '+ports.find(p=>p._id===rtn.to).nom).join(' et ')}</DialogTitle>
                <DialogContent>Souhaitez-vous mettre cette réservation sur liste d'attente?</DialogContent>
                <DialogActions>
                    <Button variant="clear" onClick={()=>{setPmr([])}}>Annuler</Button>
                    <Button variant="contained" onClick={()=>{setPmr([]); submit()}}>Mettre sur liste d'attente</Button>
                </DialogActions>
            </Dialog>
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Box>
    )
}