import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, IconButton, MenuItem, Stack, Table, TableHead, TableRow, TableBody, TableCell, TextField, Typography, Dialog, InputAdornment, Divider } from '@mui/material';
import { InfoOutlined, Search} from '@mui/icons-material';
import moment from 'moment';
import { Context } from '../App';
import { API } from '../asset/conf';
import SorTable from './sortable';
import pdfMake from "pdfmake/build/pdfmake";
import { list_transactions } from '../asset/pdfmaker';

export default function Finance() {
  const {updateConnect} = useContext(Context)
  const [datea, setDatea] = useState(moment().date(1).format('yyyy-MM-DD'))
  const [dateb, setDateb] = useState(moment().format('yyyy-MM-DD'))
  const [open, setOpen] = useState(false)
  const [action, setAction] = useState('')
  const [loading, setLoad] = useState(true)
  const [transactions, setTransacs] = useState([])
  const [transaction, setTransac] = useState()
  
  const [search_input, setSearch] = useState('')
  const [filter,setFilter] = useState('')

    const table =[
      {header:'N° Transaction', sortable: true, sortBy:(el)=>el.num, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.num}</TableCell>},
      {header:'Date', sortable: true, sortBy:(el)=>new Date(el.date).getTime(), row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{moment.utc(row.date).format('DD/MM/yyyy')}</TableCell>},
      {header:'N° Réservation', sortable: true, sortBy:(el)=>el.rsv.num, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'90px'}}>{row.rsv.num}</TableCell>},
      {header:'Client', sortable: true, sortBy:(el)=>el.rsv.contact, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'120px', textTransform:'capitalize'}}>{row.rsv.contact}</TableCell>},
      {header:'Montant', sortable: true, sortBy:(el)=>el.montant, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.montant.toFixed(2)}€</TableCell>},
      // {header:'Type', sortable: true, sortBy:(el)=>el.by, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.by}</TableCell>},
      {header:'Mode de paiement', sortable: true, sortBy:(el)=>el.mode, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.mode}</TableCell>},
      {header:'Status paiement', sortable: true, sortBy:(el)=>el.rsv.pay_status, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.rsv.pay_status}</TableCell>},
      {header:'', row:(row, index)=> <TableCell key={index} align='center' sx={{maxWidth:'90px', minWidth:'50px'}} style={{display:'flex', borderWidth:0, alignItems:'center'}} >
          <IconButton aria-label="informations complémentaires" onClick={()=>{getTransationInfos(row)}}><InfoOutlined fontSize="small"/></IconButton>
      </TableCell>},
    ]

  function getTransationInfos(transac) {
    setLoad(true); 
    fetch(API.concat(`/passagers_rotation/${JSON.stringify(transac.rsv.passagers)}/${transac.rsv.rotations[0]??''}`),{method:'GET', headers:{
      Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }}).then(async response=>{
      if (response.status===200) {
        const {psg, rtn} = await response.json()
        const trs = {...transac, passagers:psg, from: rtn.from, to:rtn.to}
        setTransac(trs)
      } else setTransac(transac); 
      manage_dialog(true, 'info')
      setLoad(false)
    })
  }

  async function manage_dialog(state, action) {
      setOpen(state)
      setAction(action)
      if (!state) {
          setTransac()
      }
  }

  async function handleCloture() {
    setLoad(true)
    manage_dialog(false, '')
    fetch(API.concat(`/reservations/payments/${moment().format('yyyy-MM-DD')}/cloture`), {method:'PATCH', headers:{
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }}).then(async(response)=>{
      const token = response.headers.get('x-auth-token')
      if (response.status === 401) {
          sessionStorage.clear()
          updateConnect(false)
      } else {
        if (token && token !== sessionStorage.getItem('token') && token !== '') {
          sessionStorage.setItem('token', response.headers.get('x-auth-token'))
        }
        if(response.status===200){
          setTransacs(old=>old.map(tr=>{return({...tr, cloture: true})}))
        }
        setLoad(false)
      }
  })
  }

  function handleExport() {
    setLoad(true)
    const pdfDocGenerator = pdfMake.createPdf(list_transactions(transactions, datea, dateb));
    pdfDocGenerator.getBlob((blob) => {
      const downloadUrl = window.URL.createObjectURL(blob)
      // Créer un lien temporaire et cliquer dessus pour déclencher le téléchargement
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'transactions-financieres.pdf'; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Libérer l'URL de l'objet
      window.URL.revokeObjectURL(downloadUrl);
      setLoad(false)
    })
  }

  function get_transactions() { 
    fetch(API.concat(`/payments/${datea}/${dateb}`), {method:'GET', headers:{
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }}).then(async(response)=>{
        const token = response.headers.get('x-auth-token')
        if (response.status === 401) {
            sessionStorage.clear()
            updateConnect(false)
        } else {
            if (token && token !== sessionStorage.getItem('token') && token !== '') {
                sessionStorage.setItem('token', response.headers.get('x-auth-token'))
            }
            if (response.status === 200) {
              const json = await response.json()
              setTransacs(json);
              setLoad(false)
            }else setLoad(false)
        }
    })
  }

  function normalize(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9\s-.€]/gi,'').toLowerCase()
  }

  function get_searchable(item) {
    return item.num+' '+item.rsv.num+' '+item.rsv.contact+' '+item.montant.toFixed(2)+' '+item.ref
  }

  function filtrer(item) {
      if(filter!=='') {
          switch (filter) {
              case 'payé':
                  return item.rsv.pay_status==='payé'
              case 'acompte':
                  return item.rsv.pay_status.toLowerCase().includes('acompte')
              default:
                  return item.rsv.pay_status.toLowerCase().includes('rembours')
          }
      } else return true
  }

  useEffect(()=>{
    get_transactions()
  }, [updateConnect])

    return (
      <Box sx={{display:'flex', flex:1, marginBottom:'30px', flexDirection:'column', paddingInline:'30px'}}>
        <Typography variant='h1' align='center' marginBottom='30px'>Gestion des Finances</Typography>
          
        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <Stack direction="row" spacing={1} sx={{alignItems:'center'}}>
            <Typography variant='subtitle1'>Date:</Typography>
            <TextField type="date" size='small' sx={{minWidth:'100px'}} label='du' InputLabelProps={{shrink: true}}
            value={datea} onChange={(e)=> {setDatea(e.target.value)}} 
            inputProps={{max: new Date().toISOString().split("T")[0]}}/>
            <TextField type="date" size='small' sx={{minWidth:'100px'}} label='au' InputLabelProps={{shrink: true}}
            value={dateb} onChange={(e)=> {setDateb(e.target.value)}} 
            inputProps={{min: new Date(datea).toISOString().split("T")[0]}}/>
            <Button onClick={get_transactions}>Appliquer</Button>
          </Stack>

          <Stack direction="row" spacing={3} sx={{alignItems:'center'}}>
            <Button variant='contained'onClick={handleExport}>exporter</Button>
            <Button variant='contained' disabled={transactions.filter(tr=>!tr.cloture).length<=0} onClick={()=>manage_dialog(true,'cloture')}>clôturer</Button>
          </Stack>
      </Box>

      <Box display='flex' justifyContent='space-between' alignItems='center' marginTop='20px'>
          <TextField
          size="small"
          label="Rechercher une transaction"
          disabled={!transactions?.length>0}
          sx={{width:'60%', minWidth:'300px'}}
          value={search_input}
          InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                  <Search />
                  </InputAdornment>
              ),
          }}
          onChange={(event)=> setSearch(event.target.value)}
          />
          <Stack direction='row' spacing={1} alignItems='center'>
              <Typography variant='subtitle1'>Filtres: </Typography>
              <TextField select
              size="small" sx={{ width:'100%', minWidth:'250px', maxWidth:'500px'}}
              label="Filtre" value={filter}
              onChange={(e)=>setFilter(e.target.value)}
              >
                  <MenuItem value='payé'>
                      <Typography>Payé</Typography> 
                  </MenuItem>
                  <MenuItem value='acompte'>
                      <Typography>Acompte</Typography> 
                  </MenuItem>
                  <MenuItem value='remboursée'>
                      <Typography>Remboursé</Typography> 
                  </MenuItem>
                  <MenuItem value=''>
                      <Typography>Indifférent</Typography> 
                  </MenuItem>
              </TextField>
          </Stack>
      </Box>

      {transactions.length>0&&<SorTable data={transactions.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt))} table={table} rowsPerPageOptions={[50,100,300]} rowsPerPage={100} />}
      
      <Typography variant='h1' sx={{textDecoration:'none'}} align='center' fontSize={20} fontWeight={600} marginBottom='20px'>TOTAUX</Typography>
      {transactions.length>0&&<Table sx={{ minWidth: 650, marginTop:2, border:'2px solid black' }} aria-label="ships table">
        <TableHead>
            <TableRow sx={{fontWeight:600}}>
                <TableCell align="center" sx={{border: '2px solid black', fontWeight:'bold', fontSize:16}}>Payé</TableCell>
                <TableCell align="center" sx={{border: '2px solid black', fontWeight:'bold', fontSize:16}}>En attente</TableCell>
                <TableCell align="center" sx={{border: '2px solid black', fontWeight:'bold', fontSize:16}}>Remboursé</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center" sx={{border: '2px solid black'}}>{transactions.reduce((tt,trs)=>tt+(['en attente', 'remboursée'].includes(trs.rsv.pay_status)?0:trs.montant),0)}€</TableCell>
            <TableCell align="center" sx={{border: '2px solid black'}}>{transactions.reduce((tt,trs)=>tt+(trs.rsv.pay_status === 'en attente' ? trs.montant : 0),0)}€</TableCell>
            <TableCell align="center" sx={{border: '2px solid black'}}>{transactions.reduce((tt,trs)=>tt+(trs.rsv.pay_status === 'remboursée' ? trs.montant : 0),0)}€</TableCell>
          </TableRow>
        </TableBody>
      </Table>}
      
      <Dialog open={open} onClose={()=>manage_dialog(false, '')} fullWidth maxWidth={action==='cloture'?'xs':'md'} sx={{minWidth:'300px'}}>
          {action==='info' && transaction && <Box display='flex' flexDirection='column' marginTop='20px' padding='20px'>
            <Typography variant='h3' textAlign='center' marginBottom='20px'>Détails de la Transaction</Typography>
            <Box display='flex' justifyContent='space-between'>
                <Box>
                  <Typography variant='subtitle1'>N° Transaction: {transaction.num}</Typography>
                  <Typography variant='subtitle1'>Mode de paiement: {transaction.mode}</Typography>
                  <Typography variant='subtitle1'>Montant: {transaction.montant.toFixed(2)}€</Typography>
                </Box>
                <Box>
                  <Typography variant='subtitle1'>Date: {moment.utc(transaction.date).format('DD/MM/yyyy')}</Typography>
                  <Typography variant='subtitle1'>Référence: {transaction.ref}</Typography>
                  {/* <Typography variant='subtitle1'>Type: {transaction.by}</Typography> */}
                </Box>
            </Box>

            <Divider variant='middle' sx={{marginTop:'20px', marginX:'15%'}}/>

            <Box display='flex' flexDirection='column' margin='30px'>
              <Typography variant='h3' textAlign='center' marginBottom='5px'>Réservation n°{transaction.rsv.num}</Typography>
              <Typography variant='h3' textAlign='center' marginBottom='20px'>{transaction.rsv.isA_R||transaction.rsv.is_journee?'Aller-Retour ':''}{transaction.from.pays} {'->'} {transaction.to.pays}</Typography>
              <Typography variant='subtitle1'>Date de départ: {moment.utc(transaction.rsv.date_depart).format('DD/MM/yyyy')}</Typography>
              <Typography variant='subtitle1'>Passagers:</Typography>
              {transaction.passagers?.sort((a,b)=>(a.nom+' '+a.prenom)<(b.nom+' '+b.prenom)?-1:1).map(psg=><Typography key={psg._id} textTransform='capitalize' marginLeft={2}>- {psg.nom.toUpperCase()} {psg.prenom}</Typography>)}
              <Typography variant="subtitle1" style={{alignSelf:'end', margin:'0 20px 20px 0'}}>Prix Total de la réservation: {transaction.rsv.total.toFixed(2)}€</Typography>
              <Typography variant="subtitle1">Déjà payé : {transaction.rsv?.payments?.reduce((total, current)=>total+current.montant,0).toFixed(2)}€</Typography>
              <Typography variant="subtitle1" color='red'>Reste à payer: {transaction.rsv?.rest_to_pay.toFixed(2)}€</Typography>
              {transaction.rsv?.updates>1&&<Typography variant="subtitle1" style={{margin:'0 20px 20px 0'}}>
                  Frais de modification: {((transaction?.rsv?.updates-1)*5).toFixed(2)}€
              </Typography>}
            </Box>
          </Box>}
          {action==='cloture'&&<Box display='flex' flexDirection='column' margin={3}>
            <Typography variant='h3' align='center'>Confirmer la clôture</Typography>
            <Typography>Vous vous apprêtez à confirmer la clôture de toutes les transactions d'aujourd'hui et antérieures</Typography>
            <Stack direction='row' spacing={2} alignSelf='end' alignItems='center' marginTop={3}>
              <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false,'')}>annuler</Button>
              <Button variant='contained' onClick={handleCloture}>confirmer</Button>
            </Stack>
          </Box>}
      </Dialog>

      <Backdrop open={loading} ><CircularProgress/></Backdrop>          
    </Box>
    );
}