import { Box, Button, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Checkbox, Select, Stack, TextField, Typography } from "@mui/material";
import React, {useState, useContext} from "react";
import { API } from "../asset/conf";
import { Context } from "../App";

export default function TarifForm(props) {
    const { updateConnect } = useContext(Context)
    const categories = [{label:'Adulte (+12 ans)', value:'adulte'}, {label:'Enfant B (5-12 ans)', value:'enfant b'}, {label:'Enfant A (1-4 ans)', value:'enfant a'}, {label:'Nourrisson (-1 ans)', value:'nourrisson'}] //{label:'Groupe', value:'groupe'}
    const [loading, setLoad] = useState(false)
    const [libelle, setLibelle] = useState(props.tarif?.libelle??'')
    const [isA_R, setA_R] = useState(props.tarif?.isA_R??true)
    const [categorie, setcategorie] = useState(props.tarif?.categorie??['adulte'])
    // const [count, setcount] = useState(props.tarif?.count??0)
    const [bag, setbag] = useState(props.tarif?.bag??2)
    const [poid_bag, setpoid_bag] = useState(props.tarif?.poid_bag??20)
    const [prix, setprix] = useState(props.tarif?.prix??0)
    const [is_journee, setJournee] = useState(props.tarif?.is_journee??false)

    function handleSubmitShip(event) {
        event.preventDefault()
        setLoad(true)
        const tarif = {
            libelle: libelle,
            isA_R: isA_R,
            categorie: categorie,
            bag: Number(bag),
            poid_bag: Number(poid_bag), // en kg
            prix: Number(prix),
            is_journee: is_journee
        }
        // if(categorie.includes('groupe')) tarif.count=Number(count)

        fetch(API.concat(props.edit ? `/tarifs/update/${props.tarif?._id}`:'/tarifs'), {method:props.edit ? 'PATCH':'POST', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify(tarif)
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    props.addTarif(json)
                    props.close()
                }
                if (response.status===403) {
                    alert('Ce tarif exist déjà')
                }
                setLoad(false)
            }
        })
    }

    return(
        <Box component='form' onSubmit={handleSubmitShip} sx={{padding:'20px'}}>
            <Typography variant="h3" textAlign='center' sx={{marginBottom:'15px'}}>Enregistrer un nouveau tarif</Typography>
            <Stack direction='column' alignItems='center' spacing={2}>
                <RadioGroup row value={isA_R} onChange={(e)=> setA_R(e.target.value)}>
                    <FormControlLabel value={true} control={<Radio />} label="Aller-Retour" />
                    <FormControlLabel value={false} control={<Radio />} label="Aller Simple" />
                </RadioGroup>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} margin="normal" color='primary'
                    value={libelle}
                    onChange={(e)=> setLibelle(e.target.value)}
                    required label="Libellé" type="text" autoFocus/>
                    <FormControl>
                        <InputLabel>Catégorie</InputLabel>
                        <Select fullWidth
                        sx={{minWidth:'200px', width:'50%'}} color='primary'
                        required label="Catégorie" multiple
                        value={categorie} onChange={(e)=> setcategorie(e.target.value)}>
                            {categories.map((cat, index) =>
                                <MenuItem key={index} value={cat.value} style={{textTransform:'capitalize'}}>{cat.label}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2}>
                    {/* {categorie.includes('groupe')&&<TextField
                    fullWidth margin="normal" color='primary' sx={{minWidth:'90px'}}
                    label="Passagers" type="number" required
                    InputProps={{ inputProps: { min: 0, step:1 } }}
                    value={count}
                    onChange={(e)=>{if(/^\d+$/.test(e.target.value)|| e.target.value==='') setcount(e.target.value)}}/>} */}
                    <TextField
                    fullWidth margin="normal" color='primary' sx={{minWidth:'90px'}}
                    label="Baggages" type="number" required
                    InputProps={{ inputProps: { min: 0, step:1 } }}
                    value={bag}
                    onChange={(e)=>{if(/^\d+$/.test(e.target.value)|| e.target.value==='') setbag(e.target.value)}}/>
                    <TextField
                    fullWidth margin="normal" color='primary' sx={{minWidth:'90px'}}
                    required label="Poid par baggage" type="number"
                    InputProps={{ inputProps: { min: 0, step:1 } }}
                    value={poid_bag}
                    onChange={(e)=>{if(/^\d+$/.test(e.target.value)|| e.target.value==='') setpoid_bag(e.target.value)}}/>
                    <TextField
                    fullWidth margin="normal" color='primary' sx={{minWidth:'90px'}}
                    required label="Prix" type="number"
                    InputProps={{ inputProps: { min: 0, step:5 } }}
                    value={prix}
                    onChange={(e)=>{if(/^\d+([.,]\d{1,2})?$/.test(e.target.value)|| e.target.value==='') setprix(e.target.value)}}/>
                </Stack>
                <FormControlLabel label='Tarif journée shopping' control={<Checkbox checked={is_journee} onChange={()=>setJournee(old=>!old)}/>}/>
                <Button
                type="submit"
                variant="contained"
                style={{alignSelf:'end'}}
                color="primary"
                disabled={loading}
                >
                    {/*/part ship + rotation, ajouter une ligne d'entrée pour chaque rotation*/}
                {loading && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } Enregistrer
                </Button>
            </Stack>
        </Box>
    )
}